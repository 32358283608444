// Generated by Framer (b75150a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["auFWOV0pZ"];

const serializationHash = "framer-ZcRNu"

const variantClassNames = {auFWOV0pZ: "framer-v-nt3wq7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({devLogo, height, id, width, ...props}) => { return {...props, Ji60ybn9_: devLogo ?? props.Ji60ybn9_} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;devLogo?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Ji60ybn9_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "auFWOV0pZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"/developers/:slug"} openInNewTab><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-nt3wq7", className, classNames)} framer-8ozxb4`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"auFWOV0pZ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fit", sizes: "183px", ...toResponsiveImage(Ji60ybn9_), ...{ positionX: "center", positionY: "center" }}} className={"framer-b1frzt"} data-framer-name={"Developer logo"} layoutDependency={layoutDependency} layoutId={"z92h_olsm"}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZcRNu.framer-8ozxb4, .framer-ZcRNu .framer-8ozxb4 { display: block; }", ".framer-ZcRNu.framer-nt3wq7 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-ZcRNu .framer-b1frzt { flex: none; height: 90px; position: relative; width: 183px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZcRNu.framer-nt3wq7 { gap: 0px; } .framer-ZcRNu.framer-nt3wq7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-ZcRNu.framer-nt3wq7 > :first-child { margin-left: 0px; } .framer-ZcRNu.framer-nt3wq7 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 90
 * @framerIntrinsicWidth 183
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Ji60ybn9_":"devLogo"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhXM56BQRu: React.ComponentType<Props> = withCSS(Component, css, "framer-ZcRNu") as typeof Component;
export default FramerhXM56BQRu;

FramerhXM56BQRu.displayName = "CMS Draw";

FramerhXM56BQRu.defaultProps = {height: 90, width: 183};

addPropertyControls(FramerhXM56BQRu, {Ji60ybn9_: {title: "Dev Logo", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerhXM56BQRu, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})